import { exhaustiveGuard } from "@mcwd/typescript-type-guards";

export const BrowserBranch = ["dev", "prod"] as const;
export type BrowserBranch = typeof BrowserBranch[number];

export const SiteEnvironment = ["prod", "stage", "dev-server", "local-dev"] as const;
export type SiteEnvironment = typeof SiteEnvironment[number];

export const BrowserEnv = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore 
  branch: BROWSER_BRANCH_VALUE as BrowserBranch,

  get env(): SiteEnvironment {
    const curHost = window.location.host;
    if (this.branch === "prod") {
      switch (curHost) {
        case "dev.mastercontrol.com": return "stage";
        case "staging.mastercontrol.com": return "stage";
        case "static-test.mastercontrol.com": return "stage";
        default: return "prod";
      }
    }
    else if (this.branch === "dev") {
      if (/^localhost(:\d+)?$/.test(curHost)) {
        return "local-dev";
      }
      else {
        return "dev-server";
      }
    }
    else {
      exhaustiveGuard(this.branch);
    }
  }

};