<script lang="ts" setup>
  import { ref, watch, computed } from 'vue';
  import { Modal as BootstrapModal } from 'bootstrap';
  import { useEventListener } from '@vueuse/core';
  import { isNil, isNotNil } from '@mcwd/typescript-type-guards';

  type TrueModalState = "BeforeRender" | "Opening" | "Open" | "Closing" | "Closed" | "Destroyed";
  const trueModalState = ref<TrueModalState>("BeforeRender");
  interface PropConfig {
    hardClose: boolean;
  }
  const props = withDefaults(defineProps<PropConfig>(), {
    hardClose : false,
  });
  const hardClose = props.hardClose ?? false;
  const desiredVisibility = ref(false);
  const needsCleanup = ref(false);

  const actualVisibility = computed(() => {
    return desiredVisibility.value === true || needsCleanup.value === true;
  });

  const modalEl = ref<HTMLElement | null>(null);
  const bsModal = ref<BootstrapModal | null>(null);

  function initModal() {
    if (isNil(bsModal.value) && modalEl.value) {
      needsCleanup.value = true;
      bsModal.value = new BootstrapModal(modalEl.value, { 
        backdrop: hardClose ? "static" : true , 
        focus: true, 
        keyboard: false 
      });
      bsModal.value.show();
    }
  }

  function openModal() {
    console.log("Opening modal");
    desiredVisibility.value = true;
  }

  function closeModal() {
    desiredVisibility.value = false;
  }

  watch([() => desiredVisibility.value, () => modalEl.value], ([requestedVisibility, modalElement]) => {
    if (isNotNil(modalElement)) {
      if (requestedVisibility) {
        initModal();
      }
      else {
        if (bsModal.value && trueModalState.value !== "Closing" && trueModalState.value !== "Closed" && trueModalState.value !== "Destroyed") {
          bsModal.value.hide();
        }
      }
    }
    else {
      needsCleanup.value = false;
      bsModal.value = null;
      trueModalState.value = "Destroyed";
    }
  });

  
  // This event fires immediately when the show instance method is called. If caused by a click, the clicked element is available as the relatedTarget property of the event.
  useEventListener(modalEl, "show.bs.modal", () => {
    console.log("show.bs.modal");
  });
  
  // This event is fired when the modal has been made visible to the user (will wait for CSS transitions to complete). If caused by a click, the clicked element is available as the relatedTarget property of the event.shown.bs.modal	
  useEventListener(modalEl, "shown.bs.modal", () => {
    console.log("shown.bs.modal");
    trueModalState.value = "Open";
  });
  
  
  // This event is fired immediately when the hide instance method has been called.
  useEventListener(modalEl, "hide.bs.modal", () => {
    console.log("hide.bs.modal");
    if (desiredVisibility.value === true){
      closeModal();
    }
  });

  // This event is fired when the modal has finished being hidden from the user (will wait for CSS transitions to complete).
  useEventListener(modalEl, "hidden.bs.modal", () => {
    console.log("hidden.bs.modal");
    trueModalState.value = "Closed";
    if (bsModal.value) {
      bsModal.value.dispose();
      bsModal.value = null;
    }
    needsCleanup.value = false;
    trueModalState.value = "Destroyed";
  });

  // This event is fired when the modal is shown, its backdrop is static and a click outside of the modal is performed. The event is also fired when the escape key is pressed and the keyboard option is set to false.
  useEventListener(modalEl, "hidePrevented.bs.modal", () => {
    console.log("hidePrevented.bs.modal");
  });

  watch(() => actualVisibility.value, (cur, prev) => {
    console.log("actualVisibility", { cur, prev });
  });


  defineExpose({
    visible: actualVisibility,
    openModal,
    closeModal
  });
</script>

<template>
  <div v-if="actualVisibility" ref="modalEl" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div v-if="!hardClose" aria-label="Close" class="close-button" @click="closeModal">
          <em class="fas fa-times"></em>
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>