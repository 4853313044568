import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["id", "data-uniq-instance-id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.FormState !== 'BeforeLoad')
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          id: `mktoForm_${$setup.FormSettings.formId}`,
          class: "d-none",
          "data-uniq-instance-id": $setup.UniqInstanceId
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    ($props.isInModal === true)
      ? (_openBlock(), _createBlock($setup["genericModal"], {
          key: 1,
          id: "eclipse-modal",
          ref: "eclipseModal",
          class: "eclipse-modal",
          "data-testid": "form-experience-root",
          "hard-close": $setup.GenericModalCloseType
        }, {
          default: _withCtx(() => [
            _createVNode($setup["asyncFormExperience"], _mergeProps($setup.props, { onCustomClose: $setup.customClose }), null, 16 /* FULL_PROPS */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["hard-close"]))
      : (_openBlock(), _createBlock($setup["asyncFormExperience"], _mergeProps({ key: 2 }, $setup.props, { "data-testid": "form-experience-root" }), null, 16 /* FULL_PROPS */))
  ], 64 /* STABLE_FRAGMENT */))
}